export const CondicoesVigentesEnem = () => {
  let linkCondicoesVigentes = <HTMLElement>document.querySelector('.modulo-enem__link-condicoes-vigentes');

  const Acao = () => {
    let linkCondicoes = <HTMLElement>document.querySelector('.modulo-enem__link-condicoes-vigentes');
    let condicoesVigentes = <HTMLElement>document.getElementById('condicoes-vigentes');

    if (condicoesVigentes.classList.contains('modulo-enem__conteudo-condicoes-vigentes--ativo')) {
      linkCondicoes.innerHTML = linkCondicoes.dataset.textoLinkCondicoes || '';
    } else {
      linkCondicoes.innerHTML = '<em>Ocultar</em>&nbsp;as condições vigentes';
    }

    condicoesVigentes.classList.toggle('modulo-enem__conteudo-condicoes-vigentes--ativo');
  }

  linkCondicoesVigentes.addEventListener('click', () => Acao());
}