const DisplayButtonFloating = (
  btnFloat: HTMLElement, heightBreakpoint: number
) => {
  let valorScroll = screen.width <= 992 ? document.body.scrollTop : window.scrollY;

  if (valorScroll > heightBreakpoint) {
    if (!btnFloat.classList.contains('div-btn-floation--active')) {
      btnFloat.classList.add('div-btn-floation--active');
    }

  } else {
    if (btnFloat.classList.contains('div-btn-floation--active')) {
      btnFloat.classList.remove('div-btn-floation--active');
    }
  }
}

export const BotaoFlutuante = () => {
  const Acao = () => {
    let btnFloat = <HTMLElement>document.querySelector('.div-btn-floating');

    if (window.innerWidth <= 992) {
      DisplayButtonFloating(btnFloat, 500);
    } else {
      DisplayButtonFloating(btnFloat, 785);
    }
  }

  window.addEventListener('scroll', () => Acao());
  document.addEventListener("scroll", () => Acao());
  document.body.addEventListener("scroll", () => Acao());
}