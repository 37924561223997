export const CxIntervencao = () => {
  const Acao = () => {
    let valorScroll = screen.width <= 992 ? document.body.scrollTop : window.scrollY;
    let botao = <HTMLElement>document.querySelector('#btnIntervencao-open');

    if (screen.width <= 992) {
      botao.classList.remove('btnIntervencao-open--ativo');

      if (valorScroll >= 400) {
        botao.classList.add('btnIntervencao-open--ativo');
      }
    }
  }

  document.addEventListener("scroll", () => Acao());
  document.body.addEventListener("scroll", () => Acao());
}