import { ModalAviseMe } from "../FormasIngresso/formasIngressoTelas";
import { PdpDataLayer } from "./pdpDataLayer";
import { PdpInformacoesRapidasCursosGraduacaoBase } from "./pdpInformacoesRapidasCursosGraduacaoBase";
import { PdpInformacoesRapidasCursosLivresBase } from "./pdpInformacoesRapidasCursosLivresBase";
import { PdpInformacoesRapidasCursosPosGraduacaoBase } from "./pdpInformacoesRapidasCursosPosGraduacaoBase";
import { TooltipTelaInvestimentoGraduacao } from "./Sidebar/pdpSidebarTooltipTelaInvestimentoGraduacao";
import { TooltipTelaUm } from "./Sidebar/pdpSidebarTooltipTelaUm";

const sideBarPdpTelaConteudo = <NodeListOf<HTMLElement>>document.querySelectorAll('.sidebar-pdp-tela__conteudo');

const DefinirMarginTopToolTipPlanoPagamentoPdp = (elemeto: HTMLElement) => {
  let valorScroll = screen.width <= 992 ? document.body.scrollTop : window.scrollY;

  if (valorScroll <= 992) {
    let scroll: number = elemeto.scrollTop;

    document.documentElement.style.setProperty(
      '--margin-top-tooltip-plano-pagamento-pdp',
      scroll <= 25 ? '25px' : `-${(scroll - 25)}px`
    );
  }
};

const IncluirNavegacao = () => {
  let elemento = <HTMLAnchorElement>document.querySelector('a.js-btn-duracaocurso');
  elemento?.setAttribute('href', "#Informacoes-rapidas");
};

export const Pdp = () => {
  sideBarPdpTelaConteudo.forEach((elemento: HTMLElement) => elemento
    .addEventListener('scroll', () => {
      DefinirMarginTopToolTipPlanoPagamentoPdp(elemento);
    })
  );

  TooltipTelaUm();
  ModalAviseMe();
  TooltipTelaInvestimentoGraduacao();

  if (document.querySelector('.pdp-novos-carrinhos-tipo--livre-base')) {
    PdpInformacoesRapidasCursosLivresBase();
  }

  if (document.querySelector('.informacoes-rapidas-coluna-tres-item-conteudos-slider')) {
    PdpInformacoesRapidasCursosPosGraduacaoBase();
  }

  if (document.querySelector('.informacoes-rapidas-coluna-dois-item-conteudos-slider')) {
    PdpInformacoesRapidasCursosGraduacaoBase();
  }

  if (document.querySelector('a.js-btn-duracaocurso')) {
    IncluirNavegacao();
  }

  PdpDataLayer();
};