import { AbrirModalCalculadoraEnem } from "./abrirModalCalculadoraEnem";
import { CondicoesVigentesEnem } from "./condicoesVigentesEnem";

export const Enem = () => {
  if (document.querySelector('.calculadora-enem-modal')) {
    CondicoesVigentesEnem();
    AbrirModalCalculadoraEnem();
  }

  if (document.querySelector('.calculadora-enem-modal') || document.querySelector('.modulo-enem')) {
    CondicoesVigentesEnem();
  }
}