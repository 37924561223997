export const TextoESlides = (modulo: HTMLElement | null) => {
  if (!modulo) {
    return;
  }

  $('.textos-e-slides .textos-e-slides__slides').slick({
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 700,
    nextArrow: <HTMLElement>document.querySelector('.textos-e-slides__arrow--avancar'),
    prevArrow: <HTMLElement>document.querySelector('.textos-e-slides__arrow--voltar'),
  });

  const slides: NodeListOf<HTMLElement> = document.querySelectorAll('.textos-e-slides__slide');
  
  slides.forEach((slide) => {
    slide.addEventListener('click', () => {
      const imagem: HTMLImageElement | null = slide.querySelector('img');
      const overlay: HTMLDivElement | null = document.querySelector('.textos-e-slides__overlay');
      const imagemDestacadaOverlay: HTMLImageElement | null = document.querySelector('.textos-e-slides__imagem-destacada .textos-e-slides__imagem--cheia');
      if (!imagem || !overlay || !imagemDestacadaOverlay) return;

      const urlImagemAlta = imagem.dataset.srcAltaResolucao ?? '';
      if (urlImagemAlta !== imagemDestacadaOverlay.getAttribute('src')) {
        imagemDestacadaOverlay.setAttribute('src', urlImagemAlta);
        imagemDestacadaOverlay.setAttribute('alt', imagem.alt);
      }

      if (!overlay.classList.contains('textos-e-slides__overlay--ativo')) {
        overlay.classList.add('textos-e-slides__overlay--ativo');
        return;
      }
      overlay.classList.remove('textos-e-slides__overlay--ativo');

    });
  });

  const botaoFecharOverlay: HTMLDivElement | null = document.querySelector('.textos-e-slides__fechar');
  botaoFecharOverlay?.addEventListener('click', () => {
    const overlay: HTMLDivElement | null = document.querySelector('.textos-e-slides__overlay');
    if (!overlay) return;

    overlay.classList.remove('textos-e-slides__overlay--ativo');
  });
}